export default {
  breakpoints: ['40em', '52em', '64em'],
  space: [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: '"Source Sans Pro", sans-serif',
    monospace: 'Roboto, sans-serif'
  },
  fontSizes: [
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    64,
    96
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  colors: {
    text: '#0B3948',
    background: '#D0CDD7',
    lightBackground: '#bbb9c2',
    primary: '#416165',
    secondary: '#D9DBF1',
    muted: '#ACB0BD',
    accent: '#54747f',
    modes: {
      dark: {
        text: '#fff',
        background: '#201148',
        lightBackground: '#535256',
        primary: '#ff34b3',
        secondary: '#55e7ff',
        muted: '#2011a2',
        accent: '#879ea5',
      }
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    },
    a: {
      color: 'text',
      '&:hover': {
          color: 'primary',
          textDecoration: 'none'
      }
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      backgroundColor: 'hsla(0, 0%, 0%, 0.1)',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    },
    hr: {
      backgroundColor: 'accent'
    }
  },
  a: {
    footer: {
      cursor: 'pointer',
      color: 'text',
      '&:hover': {
        color: 'primary'
      }
    },
    link: {
      color: 'text',
      '&:hover': {
          color: 'primary',
          textDecoration: 'none'
      }
    }
  },
  links: {
    secondary: {
      variant: 'styles.h3',
      textDecoration: 'none',
      display: 'block',
      width: "100%",
      padding: 2,
      borderRadius: 4,
      '&:hover': {
        backgroundColor: 'accent',
        color: 'secondary'
      }
    },
    third: {
      variant: 'styles.h2',
      textDecoration: 'none',
      '&:hover': {
        color: 'primary',
        textDecoration: 'underline'
      }
    },
    button: {
      fontSize: 3,
      transition: '0.2s background-color',
      textDecoration: 'none',
      color: 'text',
      borderRadius: '4px',
      padding: 2,
      display: 'inline-block',
      whiteSpace: 'nowrap',
      marginRight: 2,

      '&:hover': {
        backgroundColor: 'lightBackground'
      },

      '@media (max-width: 768px)': {
        padding: 2,
        fontSize: 5,
        zIndex: 6,
        marginBottom: 2
      }
    },
  },
  text: {
    subtitle: {
      borderBottom: '1px solid',
      borderColor: 'accent',
      paddingBottom: 1,
    }
  }
}